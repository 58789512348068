
/*/////////////////////////////////////////////////MISC/*/

.inset,
.inset input,
.inset input::placeholder {
  font-weight: bold;
  color: rgba(0,0,0,0.2);
  text-shadow: 1px 1px 0px rgba(255,255,255,0.5), 0px 0px 0px rgba(0,0,0,0.2);
}
.border-left {
  border-left: 1px solid rgba(255,255,255,0.5);
  box-shadow: -1px 0px 0px 0px rgba(0,0,0,0.2);
}
.border-top {
  border-top: 1px solid rgba(255,255,255,0.5);
  box-shadow: 0px -1px 0px 0px rgba(0,0,0,0.2);
}

/*/////////////////////////////////////////////////MARKUP/*/

* {
  margin: 0;
  padding: 0;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; /*https://blog.55minutes.com/2012/04/iphone-text-resizing/*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent; /*https://stackoverflow.com/a/3516243/7662622 */
}

html, body, #root, #root>* {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html, body {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  color: black;
}
body {
  transition: background-color .2s ease-in;
}
body,section,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,p,blockquote,table,thead,tbody,tr,th,td,select,iframe,textarea,img,figure,label,button,input,small {
  margin: 0;
  padding: 0;
}
.bl {
  background-size: auto 1.5em;
  background-position: top left;
  background-image: linear-gradient(lightgrey, transparent 1px);
  background-attachment: local;
}
.sm {
  font-size: 0.7500em;
  line-height: 2.0000em;
}
.sm-m-h {
  margin: calc(0.5*2.0000em);
}
.sm-p-h-s {
  padding-left: calc(0.5*2.0000em);
  padding-right: calc(0.5*2.0000em);
}
.sm-m-1 {
  margin: calc(1*2.0000em);
}
.md,
.md > *,
body,section,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,p,blockquote,table,thead,tbody,tr,th,td,select,iframe,textarea,img,figure,label,button,input,small {
  font-size: 1.0000em;
  line-height: 1.15;
}
.md-p-h {
  padding: calc(0.5*1.5000em);
}
.md-p-h-w {
  padding-left: calc(0.5*1.5000em);
  padding-right: calc(0.5*1.5000em);
}
.md-p-1 {
  padding: 1.5000em;
}
.md-m-h {
  margin: calc(0.5*1.5000em);
}
.md-m-h-l {
  margin-left: calc(0.5*1.5000em);
}
.md-m-h-t {
  margin-top: calc(0.5*1.5000em);
}
.md-m-h-b {
  margin-bottom: calc(0.5*1.5000em);
}
.md-m-h-w {
  margin-left: calc(0.5*1.5000em);
  margin-right: calc(0.5*1.5000em);
}
.md-m-1 {
  margin: 1.5000em;
}
.md-m-1-h {
  margin-top: 1.5000em;
  margin-bottom: 1.5000em;
}
.md-m-1-t {
  margin-top: 1.5000em;
}
.lg,
h1 {
  font-size: 1.5000em;
  line-height: 1.0000em;
}
.lg-m-h-t {
  margin-top: calc(0.5*1.0000em);
}
.lg-m-h-b {
  margin-bottom: calc(0.5*1.0000em);
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}
li {
  text-decoration: none;
}

input {
  appearance: none;
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
}
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px white inset; }

/*/////////////////////////////////////////////////BOX MODEL/*/

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
.row-nowrap {
  flex-wrap: nowrap;
}
.row-reverse {
  flex-flow: row-reverse wrap;
}
.row-column {
  flex-flow: column wrap;
  justify-content: flex-start;
}
.row-left {
  justify-content: flex-start;
  text-align: left;
}
.row-center {
  justify-content: center;
  text-align: center;
}
.row-right {
  justify-content: flex-end;
  text-align: right;
}
.col-100 { flex: 0 0 100%; }
.col-80 { flex: 0 0 80%; }
.col-70 { flex: 0 0 70%; }
.col-65 { flex: 0 0 65%; }
.col-60 { flex: 0 0 60%; }
.col-50 { flex: 0 0 50%; }
.col-45 { flex: 0 0 45%; }
.col-40 { flex: 0 0 40%; }
.col-35 { flex: 0 0 35%; }
.col-30 { flex: 0 0 30%; }
.col-25 { flex: 0 0 25%; }
.col-20 { flex: 0 0 20%; }
.col-15 { flex: 0 0 15%; }
.col-10 { flex: 0 0 10%; }
.col-5 { flex: 0 0 5%; }
.col-6 { flex: 0 0 66.666%; }
.col-3 { flex: 0 0 33.333%; }
.col { overflow: hidden; }
@media screen and (max-width: 48rem) {
  .col { flex: 0 0 100%; }
  .col-50-m { flex: 0 0 50%; }
}

.block { display: block; }
.inblock { display: inline-block; }

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }

.centering {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centered {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
}

.corner-tl {
  top: 0;
  left: 0;
}

.fill-w {
  width: 100%;
}
.fill-h {
  height: 100%;
}
.fill {
  width: 100%;
  height: 100%;
}

.below { z-index: -10; }
.above { z-index: 10; }

.scroll-y {
  /* padding-right: 50px;
  width: calc(100% + 50px); */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: scroll;
}
.scroll-y::-webkit-scrollbar { display: none; }

.hidden { overflow: hidden; }
.none { display: none; }

.transform {
  transform-style: preserve-3d;
  perspective: none;
  /* will-change: transform; */
}

.noevents { pointer-events: none; }
.events { pointer-events: auto; }

/*/////////////////////////////////////////////////STYLE/*/

.grid {
  background-size: 40px 40px;
  /* background-position: center center; */
  background-position: -1px -1px;
  /* background-image: linear-gradient(lightgrey, transparent 2px), linear-gradient(90deg, lightgrey, transparent 2px); */
  background-image: linear-gradient(lightgrey, transparent 1px), linear-gradient(90deg, lightgrey, transparent 1px);
}

.back-black { background-color: rgba(54,54,69,1); }
.back-gray { background-color: rgba(153,153,169,1); }
.back-white { background-color: rgba(245,245,245,1); }
.back-red { background-color: rgba(255,0,0,.8); }

.contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.left { text-align: left; }
.center { text-align: center; }
.right { text-align: right; }
.nowrap { white-space: nowrap; }
.ellipsis { text-overflow: ellipsis; }
.underline { text-decoration: underline; }
.white { color: rgba(245,245,245,1); }
.red { color: rgba(255,0,0,.8); }

.bor {
  border: 1px solid lightgrey;
  border-collapse: collapse;
}
.outline {
  outline: 6px solid rgba(255,0,0,.8);
  /* outline-offset: -6px; */
}

.shad { box-shadow: .3em .3em 0 rgba(0,0,0,0.2); }

.no-select { user-select: none; }

.pointer { cursor: pointer; }

.anim { transition: all .2s ease-in; }
.anim-back { transition: background-color .2s ease-in; }

.radius { border-radius: 1em; }

.cross { cursor: crosshair; }

.lh, .lh * { line-height: 1.5em; }

.origin-center { transform-origin: center; }

.shadow, .no-shadow { transition: box-shadow .2s ease-in; }
.shadow { box-shadow: .4em .4em 2em 0 rgba(0,0,0,0.2); }
.no-shadow { box-shadow: 0 0 0 0 rgba(0,0,0,0.2); }

.shadowt, .no-shadowt { transition: text-shadow .2s ease-in; }
.shadowt { text-shadow: -.05em .05em .05em rgba(0,0,0,0.3); }
.no-shadowt { text-shadow: 0 0 0 rgba(0,0,0,0.2); }

.editor p:not(:last-child) { margin-bottom: 1em; }
